<script lang="ts">
    import { mdiChevronDown } from '@mdi/js'
    import { clickoutside } from '@svelte-put/clickoutside'
    import { fade } from 'svelte/transition'

    import Box from '../atoms/Box.svelte'
    import Icon from '../atoms/Icon.svelte'
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let active = false
    export let useOnlyClick = false

    let open = false
    function toggleDropdown() {
        open = !open
    }
    function openDropdown() {
        if (useOnlyClick) {
            return
        }

        open = true
    }
    function closeDropdown() {
        if (useOnlyClick) {
            return
        }

        open = false
    }

    $: classes = concatClasses(['dropdown', classNames])
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
    class={classes}
    on:mouseover={() => openDropdown()}
    on:focus={() => openDropdown()}
    on:mouseout={() => closeDropdown()}
    on:blur={() => closeDropdown()}
    on:clickoutside={() => (open = false)}
    use:clickoutside
>
    <button
        class={`dropdown-link link  hover:bg-transparent ${
            active ? 'active' : ''
        }`}
        data-dropdown={true}
        type="button"
        on:click={toggleDropdown}
        on:mouseover={() => openDropdown()}
        on:focus={() => openDropdown()}
    >
        <slot name="link" />
        <Icon
            class={`dropdown-icon ${open ? 'active' : ''}`}
            icon={mdiChevronDown}
            size={20}
        />
    </button>

    {#if open}
        <div transition:fade={{ duration: 150 }}>
            <Box class="dropdown-menu">
                <slot />
            </Box>
        </div>
    {/if}
</div>

<style global lang="postcss">
    .dropdown {
        position: relative;
    }

    .dropdown-menu {
        position: absolute;
        top: calc(100% + 1rem);
        z-index: theme('zIndex.40');
        padding: theme('spacing.4');
    }

    .dropdown-link {
        display: flex;
        gap: theme('spacing.1');
        align-items: center;
    }

    .dropdown-icon {
        transition: transform 0.15s ease-in-out;
    }

    .dropdown-icon.active {
        transform: rotate(180deg);
    }
</style>
