<script lang="ts">
    let classNames = ''
    export { classNames as class }
</script>

<svg
    class="h-5 {classNames}"
    version="1.2"
    viewBox="0 0 957 133"
    xmlns="http://www.w3.org/2000/svg"
{...$$restProps}
>
    <path
        d="M5.3 119c-3.6 0-5.2-1.6-5.2-5.3V19.1c0-3.6 1.6-5.2 5.2-5.2h15.2c3.8 0 6.1 1 8.6 3.9l28.4 32.4V19.1c0-3.6 1.6-5.2 5.2-5.2h21.9c3.7 0 5.3 1.6 5.3 5.2v94.6c0 3.7-1.6 5.3-5.3 5.3H62.7c-3.6 0-5.2-1.6-5.2-5.3V95.8l-25-30.2v48.1c0 3.7-1.6 5.3-5.3 5.3zm148.5 2.2c-36.5 0-45.4-12-45.4-29.8V41.6c0-17.8 8.9-29.9 45.4-29.9s45.3 12.1 45.3 29.9v49.8c0 17.8-8.8 29.8-45.3 29.8zm0-28.2c10.7 0 12.3-2.9 12.3-6.9V46.9c0-3.8-1.6-6.7-12.3-6.7-10.5 0-12.3 2.9-12.3 6.7v39.2c0 4 1.8 6.9 12.3 6.9zm69.1 26c-3.7 0-5.3-1.6-5.3-5.3V19.1c0-3.6 1.6-5.2 5.3-5.2h23.5c3 0 4.9 1.1 6.7 4.4l13.1 23.8c1.1 1.7 1.5 2.4 2.7 2.4h1.3c1.1 0 1.6-.7 2.6-2.4l13-23.8c1.7-3.3 3.6-4.4 6.7-4.4h23.7c3.6 0 5.2 1.6 5.2 5.2v94.6c0 3.7-1.6 5.3-5.2 5.3h-22.5c-3.8 0-5.3-1.6-5.3-5.3V62.4l-8.6 15.9c-1.7 3.3-3.9 4.6-7.7 4.6h-5.9c-3.8 0-6-1.3-7.7-4.6l-8.6-15.9v51.3c0 3.7-1.6 5.3-5.3 5.3zm162.5 2.2c-36.5 0-45.4-12-45.4-29.8V41.6c0-17.8 8.9-29.9 45.4-29.9s45.3 12.1 45.3 29.9v49.8c0 17.8-8.8 29.8-45.3 29.8zm0-28.2c10.7 0 12.3-2.9 12.3-6.9V46.9c0-3.8-1.6-6.7-12.3-6.7-10.5 0-12.2 2.9-12.2 6.7v39.2c0 4 1.7 6.9 12.2 6.9zm69.1 26c-3.7 0-5.3-1.6-5.3-5.3V19.1c0-3.6 1.6-5.2 5.3-5.2h49.2c24.5 0 35.9 9.5 35.9 29.8v45.5c0 20.3-11.4 29.8-35.9 29.8zm27.9-27.6h17.3c4.7 0 6.9-2.2 6.9-7.1V48.8c0-4.8-2.2-7-6.9-7h-17.3zm119.5 29.8c-36.5 0-45.4-12-45.4-29.8V41.6c0-17.8 8.9-29.9 45.4-29.9 36.5-.1 45.3 12.1 45.3 29.9v49.8c0 17.8-8.8 29.8-45.3 29.8zm0-28.2c10.7 0 12.3-2.9 12.3-6.9V46.8c0-3.8-1.6-6.7-12.3-6.7-10.5 0-12.2 2.9-12.2 6.7v39.3c0 4 1.7 6.9 12.2 6.9z"
        fill="var(--white, #fff)"
        fill-rule="evenodd"
    />
    <path
        d="M668.9 119c-3.7 0-5.3-1.6-5.3-5.3V84.1c0-3.8 1.6-5.2 5.3-5.2h29.5c3.6 0 5.2 1.4 5.2 5.2v29.6c0 3.7-1.6 5.3-5.2 5.3zm50.5-5.3V96.4c0-3.7 1.6-5.3 5.3-5.3h14.4v-49h-14.4c-3.7 0-5.3-1.6-5.3-5.3V19.1c0-3.6 1.6-5.2 5.3-5.2h62.2c3.6 0 5.2 1.6 5.2 5.2v17.7c0 3.7-1.6 5.3-5.2 5.3h-14.2v49h14.2c3.6 0 5.2 1.6 5.2 5.3v17.3c0 3.7-1.6 5.3-5.2 5.3h-62.2c-3.7 0-5.3-1.6-5.3-5.3zm134.5 7.5c-36.5 0-45.4-12-45.4-29.8V41.6c0-17.8 8.9-29.9 45.4-29.9s45.2 12.1 45.2 29.9v49.8c0 17.8-8.7 29.8-45.2 29.8zm0-28.2c10.6 0 12.2-2.9 12.2-6.9V46.9c0-3.8-1.6-6.7-12.2-6.7-10.5 0-12.3 2.9-12.3 6.7v39.2c0 4 1.8 6.9 12.3 6.9zm70.5-87c.4-3.7 1.6-5.3 5.2-5.3h21.2c3.7 0 5.7 1.6 5.3 5.3l-17.5 120.9c-.5 3.6-1.7 5.2-5.3 5.2h-21.2c-3.6 0-5.7-1.6-5.2-5.2z"
        fill="var(--primary-200, hsl(201 20% 72%))"
        fill-rule="evenodd"
    />
</svg>
