<script lang="ts">
    import Container from '../atoms/Container.svelte'
    import Logo from '../atoms/Logo.svelte'

    let classNames = ''
    export { classNames as class }
</script>

<header class="header {classNames}" {...$$restProps}>
    <Container class="flex items-center justify-between">
        <div class="flex items-center justify-start gap-x-3">
            <a aria-label="Back to homepage" href="/">
                <slot name="logo">
                    <Logo />
                </slot>
            </a>

            {#if $$slots.page}
                <span class="mt-1 font-display text-lg">
                    <slot name="page" />
                </span>
            {/if}
        </div>

        <slot />
    </Container>
</header>

<style global lang="postcss">
    .header {
        position: sticky;
        top: 0;
        z-index: theme('zIndex.40');
        padding-top: theme('spacing.3');
        padding-bottom: theme('spacing.3');
        color: theme('colors.white');
        background: var(--primary-700, var(--nmd-800));

        .link {
            padding: theme('spacing.1') theme('spacing.2');
            text-decoration: none;

            @screen sm {
                padding: theme('spacing.1') theme('spacing.3');
            }
        }

        .dropdown-link {
            padding-right: theme('spacing.1');

            @screen sm {
                padding-right: theme('spacing.2');
            }
        }

        .dropdown-menu {
            top: calc(100% + theme('spacing.1'));
            right: -1px;
            border: theme('colors.nmd-700') solid 1px;
        }
    }
</style>
