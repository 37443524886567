<script lang="ts">
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let active = false
    export let hover = false
    export let href: string

    $: classes = concatClasses([
        'link',
        active ? 'active' : '',
        hover ? 'hover' : '',
        classNames,
    ])
</script>

<a class={classes} {href} on:click {...$$restProps}>
    <slot />
</a>

<style global lang="postcss">
    .link {
        padding: theme('spacing.1') theme('spacing.3');
        text-decoration: underline;
        border-radius: theme('borderRadius.sm');
    }

    .link:hover,
    .link.hover {
        text-decoration: none;
        color: theme('colors.white');
        background-color: theme('colors.green.600');
    }

    .link.active {
        text-decoration: none;
        color: theme('colors.white');
        background-color: theme('colors.nmd-700');
    }
</style>
