<script lang="ts">
    import type { BoxVariants, Element } from '../types'

    let classNames = ''
    export { classNames as class }
    export let element: Element = 'div'
    export let variant: BoxVariants = 'white'
</script>

<svelte:element
    this={element}
    class="box box-{variant} {classNames}"
    {...$$restProps}
>
    <slot />
</svelte:element>

<style global lang="postcss">
    .box {
        padding: theme('spacing.6');
        border-radius: theme('borderRadius.DEFAULT');
    }

    .box-white {
        color: theme('colors.nmd-700');
        background-color: theme('colors.white');
    }

    .box-light {
        color: theme('colors.nmd-700');
        background-color: theme('colors.gray-50');
    }

    .box-dark {
        color: theme('colors.nmd-50');
        background-color: theme('colors.nmd-700');
    }
</style>
