<script context="module" lang="ts">
    import { get, writable } from 'svelte/store'

    const active = writable(false)
    export function openMenu() {
        active.set(true)
    }

    export function closeMenu() {
        active.set(false)
    }

    export function toggleMenu() {
        active.set(!get(active))
    }
</script>

<script lang="ts">
    import MediaQuery from 'svelte-media-queries'

    import Flex from '../atoms/Flex.svelte'
    import Dropdown from './Dropdown.svelte'

    let classNames = ''
    export { classNames as class }
</script>

<MediaQuery query="(min-width: 992px)" let:matches>
    {#if matches}
        <Flex
            class="lg:gap-3 {classNames}"
            alignItems="center"
            justifyContent="start"
            {...$$restProps}
        >
            <slot name="top" />
            <slot />
        </Flex>
    {:else}
        <Flex class="gap-1 sm:gap-2" alignItems="center" justifyContent="end">
            <slot name="top" />

            {#if $$slots.default}
                <Dropdown>
                    <svelte:fragment slot="link">Menu</svelte:fragment>

                    <Flex class="gap-2 whitespace-nowrap" direction="col">
                        <slot />
                    </Flex>
                </Dropdown>
            {/if}
        </Flex>
    {/if}
</MediaQuery>

<style global lang="postcss">
    .menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: theme('zIndex.50');
        width: 100%;
        padding: theme('spacing.2') 0;
        background: var(--primary-700, var(--nmd-800));
    }
</style>
