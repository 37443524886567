<script lang="ts">
    let classNames = ''
    export { classNames as class }
    export let icon: string
    export let size = 16
</script>

<svg
    class={classNames}
    fill="currentColor"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    {...$$restProps}
>
    <path d={icon} />
</svg>
