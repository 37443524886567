<script lang="ts">
    import { getSiteLink, Site } from '@nomodo/links'
    import { closeMenu, Flex, Header, Link, Menu } from '@nomodo/ui'

    import { page } from '$app/stores'
</script>

{#if $$slots.default}
    <Header>
        <Menu>
            <Flex
                slot="top"
                class="gap-1 sm:gap-2 lg:gap-3"
                alignItems="center"
            >
                <Link
                    active={$page.url.pathname === '/apps'}
                    href="/apps"
                    on:click={closeMenu}
                >
                    Apps
                </Link>
            </Flex>
            <Link
                active={$page.url.pathname === '/about'}
                href="/about"
                on:click={closeMenu}
            >
                About us
            </Link>
            <Link
                hover
                href={`${getSiteLink(Site.admin)}/login`}
                on:click={closeMenu}
            >
                Log in
            </Link>
        </Menu>
    </Header>
    <main>
        <slot />
    </main>
{/if}
